<template>
  <div :class="{ 'money__inline': inline }">{{ formatted }}</div>
</template>

<script setup>
import { computed } from "vue";
import { useShared } from "@/helpers/vueHelpers.js";

const props = defineProps({
  currency: String,
  value: Number,
  inline: {type: Boolean, default: false},
  subunitsValue: {type: Boolean, default: true},
  options: {type: Object, default: () => ({currencyDisplay: "code"})}
});

const {locale} = useShared();

const hideSubunits = computed(() => props.value.toFixed().slice(-2) === '00' || Number(props.value) === 0);

const formatted = computed(() => {
  let value = props.value;
  if (Number.isFinite(value)) {
    try {
      let formatOptions = {style: 'currency', currency: props.currency}
      let numFormat = new Intl.NumberFormat(locale, formatOptions);
      let {minimumFractionDigits} = numFormat.resolvedOptions();
      if (props.subunitsValue) {
        value = value / 10 ** minimumFractionDigits;
      }
      if (hideSubunits.value) {
        formatOptions = {style: 'currency', currency: props.currency, minimumFractionDigits: 0, maximumFractionDigits: 0};
      }
      formatOptions = {
        ...formatOptions,
        ...props.options
      }
      numFormat = new Intl.NumberFormat(locale, formatOptions);
      return numFormat.format(value);
    } catch (err) {
      return err.message;
    }
  } else {
    return '#NaN!';
  }
});
</script>

<style lang="scss" scoped>
.money__inline {
  display: inline-block;
}
</style>